import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TintucService } from 'src/app/services/tintuc.service';

@Component({
  selector: 'app-lienhe',
  templateUrl: './lienhe.component.html',
  styleUrls: ['./lienhe.component.css']
})
export class LienheComponent implements OnInit {
  hoten: string = '';
  sodt: string = '';
  email: string = '';
  cauhoi: string = '';
  tieude: string = '';
  constructor(private messageService: MessageService, private tintucService: TintucService) { }

  ngOnInit(): void {
  }
  guicauhoi() {
    if (this.hoten == '' || this.sodt == '' || this.email == '' || this.cauhoi == '') {
      this.showMessage('error', 'Vui lòng nhập đủ thông tin!');
      return;
    }
    debugger;
    var dt = {
      hoten: this.hoten,
      sodt: this.sodt,
      email: this.email,
      cauhoi: this.tieude + ': ' + this.cauhoi
    };
    this.tintucService.sendMail(dt).subscribe(
      kq => {
        if (kq) {
          this.showMessage('success', 'Bạn đã để lại lời nhắn thành công!');
          this.hoten = '';
          this.sodt = '';
          this.email = '';
          this.tieude = '';
          this.cauhoi = '';
        } else {
          this.showMessage('error', 'Có lỗi trong quá trình gửi thông tin!');
        }
      }
    );

  }
  showMessage(ketqua: string, noidung: string) {
    if (ketqua == 'success')
      this.messageService.add({ severity: ketqua, summary: 'Thông báo', detail: noidung, life: 3000 });
    else
      this.messageService.add({ severity: ketqua, summary: 'Thông báo', detail: noidung });
  }
}
