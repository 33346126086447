export class baidang{
    public IDBaiDang:number;
    public TenBaiDang: string;
    public TieuDe:string;
    public UrlRequire:string;
    public AnhDaiDien:string;
    public NoiDung:string;
    public IDTaiKhoan: string;
    public NgayDang:string;
    public TrangThaiBaiDang:string;
    public ChuyenMuc_ID:number;
    public TrichYeu:string;
    public Ten_chuyenmuc:string;
}