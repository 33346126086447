import { Component, ElementRef, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TintucService } from 'src/app/services/tintuc.service';
import './chatbox.js';
@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.css', './site-layout.component.scss']
})
export class SiteLayoutComponent implements OnInit {
  hoten: string = '';
  sodt: string = '';
  email: string = '';
  cauhoi: string = '';
  constructor(public elementRef: ElementRef, private messageService: MessageService, private tintucService: TintucService) { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    var s9 = document.createElement("script");
    var s10 = document.createElement("script");
    s9.type = "text/javascript";
    s10.type = "text/javascript";
    s9.src = "../assets/HDSoft/js/main.js";
    s10.src = "../assets/HDSoft/js/practice-animation.js";
    this.elementRef.nativeElement.appendChild(s9);
    this.elementRef.nativeElement.appendChild(s10);
  }
  guicauhoi() {
    if (this.hoten == '' || this.sodt == '' || this.email == '' || this.cauhoi == '') {
      this.showMessage('error', 'Vui lòng nhập đủ thông tin!');
      return;
    }
    var dt = {
      hoten: this.hoten,
      sodt: this.sodt,
      email: this.email,
      cauhoi: this.cauhoi
    };
    this.tintucService.sendMail(dt).subscribe(
      kq => {
        if (kq) {
          this.showMessage('success', 'Bạn đã thực hiện thành công!');
          showChatBox();
        } else {
          this.showMessage('error', 'Có lỗi trong quá trình gửi thông tin!');
        }
      }
    );

  }
  showMessage(ketqua: string, noidung: string) {
    if (ketqua == 'success')
      this.messageService.add({ severity: ketqua, summary: 'Thông báo', detail: noidung, life: 3000 });
    else
      this.messageService.add({ severity: ketqua, summary: 'Thông báo', detail: noidung });
  }
}
