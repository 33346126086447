import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TintucService } from 'src/app/services/tintuc.service';
import { chuyenmuc } from 'src/app/models/chuyenmuc';
import { MenuModel } from 'src/app/models/MenuModel';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.css', './top-navbar.component.scss']
})
export class TopNavbarComponent implements OnInit {
  items: MenuItem[];
  itemsMn: MenuModel[];
  list_chuyenmuc: chuyenmuc[];
  title = 'Quản lý tin tức';
  ngOnInit() {
    // this.getListChuyenMuc('');
    this.getListMenu();
  }
  // getListChuyenMuc(strLink: string): void {
  //   console.log(`Data-update: ${JSON.stringify(strLink)}`);
  //   this.tintucService.getListChuyenMuc().subscribe(
  //     (cm) => {
  //       this.list_chuyenmuc = cm;
  //       this.itemsMn = [];
  //       var ittt: MenuModel = {
  //         label: 'Trang chủ',
  //         url: 'home',
  //         icon: '',
  //         routerLinkActiveOptions: true,
  //         routerLink: 'home',
  //         styleClass: 'active menu-border',
  //         command: ''
  //       };
  //       if (strLink != '' && strLink != undefined) {
  //         debugger;
  //         ittt.styleClass = '';
  //       }
  //       this.itemsMn.push(ittt);

  //       this.list_chuyenmuc.forEach(element => {
  //         var it: MenuModel = {
  //           label: '',
  //           url: '',
  //           icon: '',
  //           routerLinkActiveOptions: false,
  //           routerLink: '',
  //           styleClass: '',
  //           command: (event) => { this.oncommand(event) }
  //         };
  //         it.label = element.TenCM;
  //         it.url = element.Link;
  //         it.routerLink = element.Link;
  //         if (strLink == element.Link) {
  //           it.styleClass = "active menu-border";
  //           it.routerLinkActiveOptions = true;
  //         }
  //         this.itemsMn.push(it);
  //       });
  //       this.items = this.itemsMn;
  //     }
  //   );
  // }
  getListMenu() {
    this.tintucService.getListMenu().subscribe(
      (mn) => {
        this.items = mn;
        this.items.forEach(element => {
          element.command = (event) => { this.oncommand(event) }
        });
      }
    );
  }
  oncommand(event) {
    try {
      console.log(event.item.routerLink);
      for (let index = 0; index < this.items.length; index++) {
        let element = this.items[index];
        if (event.item.routerLink == element.routerLink) {
          element.styleClass = "active menu-border";
          element.routerLinkActiveOptions = true;
        }
        else {
          element.styleClass = "";
          element.routerLinkActiveOptions = false;
        }
      }
    } catch (error) {
      console.log('Error: ' + error);
    }

  }
  constructor(private tintucService: TintucService, private routes: ActivatedRoute) {

  }
}
