import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaidangComponent } from './admin/baidang/baidang.component';
import { ChitietbdComponent } from './admin/chitietbd/chitietbd.component';
import { ChuyenmucComponent } from './admin/chuyenmuc/chuyenmuc.component';
import { HomeadminComponent } from './admin/homeadmin/homeadmin.component';
import { LoginComponent } from './admin/login/login.component';
import { TintucService } from './services/tintuc.service';
import { CttintucComponent } from './users/cttintuc/cttintuc.component';
import { DstintucComponent } from './users/dstintuc/dstintuc.component';
import { HomeComponent } from './users/home/home.component';
import { LienheComponent } from './users/lienhe/lienhe.component';
import { NotfoundpageComponent } from './users/notfoundpage/notfoundpage.component';
import { TintucComponent } from './users/tintuc/tintuc.component';
import { P404Component } from './views/error/404.component';
import { AuthGuard } from './_helpers/auth.guard';
import { AdminLayoutComponent } from './_layout/admin-layout/admin-layout.component';
import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';

const routes: Routes = [
  // App routes goes here here
  {
    path: 'admin',
    data: {
      title: 'Trang chủ quản trị'
    },
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'baidang', pathMatch: 'full' },
      { path: 'home', redirectTo: 'baidang', pathMatch: 'full' },
      // {
      //   path: 'home', component: HomeadminComponent, data: {
      //     title: 'Trang chủ quản trị'
      //   }
      // },
      {
        path: 'chuyenmuc', component: ChuyenmucComponent, data: {
          title: 'Chuyên mục'
        }
      },
      {
        path: 'baidang', component: BaidangComponent, data: {
          title: 'Bài đăng'
        }
      },
      {
        path: 'baidang/:id', component: ChitietbdComponent, data: {
          title: 'Chi tiết bài đăng'
        }
      },
      {
        path: 'baidang/add', component: ChitietbdComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'notfound',
    component: P404Component
  },
  //Site routes goes here 
  {
    path: '',
    data: {
      title: 'Trang chủ quản trị'
    },
    component: SiteLayoutComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home', component: HomeComponent, data: {
          title: 'Trang chủ quản trị'
        }
      },
      // { path: 'notfound', component: NotfoundpageComponent },
      { path: 'tin-tuc', component: TintucComponent },
      { path: 'lien-he', component: LienheComponent },
      { path: 'tin-tuc/:UrlRequire', component: CttintucComponent },
      { path: ':Link', component: DstintucComponent },
      { path: ':Link/:UrlRequire', component: DstintucComponent },
      { path: '**', redirectTo: 'notfound' },
    ]
  },



  // //no layout routes
  // { path: 'login', component: LoginComponent},
  // { path: 'register', component: RegisterComponent },
  // // otherwise redirect to home
  { path: '**', redirectTo: '' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
