import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { allbaidang } from 'src/app/models/allbaidang';
import { baidang } from 'src/app/models/baidang';
import { TintucService } from 'src/app/services/tintuc.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit  {
  lisbd: baidang[];
  listallbaidang: allbaidang;
  ten_cm: string = '';
  selectBaiDang: baidang = {
    AnhDaiDien: '',
    ChuyenMuc_ID: 0,
    IDBaiDang: 0,
    IDTaiKhoan: '',
    NgayDang: '',
    NoiDung: '',
    TenBaiDang: '',
    TieuDe: '',
    TrangThaiBaiDang: '',
    TrichYeu: '',
    UrlRequire: '',
    Ten_chuyenmuc: ''
  };
  lisgp: baidang[];
  lisdv: baidang[];
  listt: baidang[];
  showlistgp = false;
  showlisdv = false;
  showlistt = false;
  constructor(public elementRef: ElementRef, private routes: ActivatedRoute, private tintucService: TintucService, private router: Router) { }

  ngOnInit(): void {
    // this.getListBaidang();
    this.getAllListBaidang();
    window.scrollTo(0, 0);
  }
  ngAfterView() {
    // this.getAllListBaidang()
    //debugger;
    var s9 = document.createElement("script");
    var s10 = document.createElement("script");
    s9.type = "text/javascript";
    s10.type = "text/javascript";
    s9.src = "../assets/HDSoft/js/main.js";
    s10.src = "../assets/HDSoft/js/practice-animation.js";
    this.elementRef.nativeElement.appendChild(s9);
    this.elementRef.nativeElement.appendChild(s10);
  }
  getAllListBaidang() {
    this.tintucService.getAllListBaiDang().subscribe(
      mv => {
        
        this.lisgp = mv.filter(val => val.Ten_chuyenmuc === 'GIẢI PHÁP');
        this.lisdv = mv.filter(val => val.Ten_chuyenmuc === 'DỊCH VỤ');
        this.listt = mv.filter(val => val.Ten_chuyenmuc === 'TIN TỨC');
        this.ngAfterView();
        // for (let index = 0; index < mv.length; index++) {
        //   const element = mv[index];
        //   if (element[0].Ten_chuyenmuc == 'GIẢI PHÁP') {
        //     this.lisgp = element;
        //     this.showlistgp = true;
        //   }
        //   if (element[0].Ten_chuyenmuc == 'DỊCH VỤ') {
        //     this.lisdv = element;
        //     this.showlisdv = true;
        //   }
        //   if (element[0].Ten_chuyenmuc == 'TIN TỨC') {
        //     this.listt = element;
        //     this.showlistt = true;
        //   }
        // }
      }
    );
  }
}
