import { Component, ElementRef, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// import '../assets/HDSoft/js/bootstrap.min.js';
// import '../assets/HDSoft/js/jquery-3.3.1.min.js';
// import '../assets/HDSoft/js/jquery.countdown.min.js';
// import '../assets/HDSoft/js/jquery.nice-select.min.js';
// import '../assets/HDSoft/js/jquery.zoom.min.js';
// import '../assets/HDSoft/js/jquery.dd.min.js';
// import '../assets/HDSoft/js/jquery.slicknav.js';
// import '../assets/HDSoft/js/owl.carousel.min.js';
// import '../assets/HDSoft/js/particles.min.js';
// import '../assets/HDSoft/js/main.js';
//import '../assets/HDSoft/js/practice-animation.js';
// import '../assets/HDSoft/js/bootstrap.min.js';
// import * as jquery from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}



