import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { TokenStorageService } from '../services/token-storage.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private token: TokenStorageService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        const token = this.token.getToken();
        if (token != null) {
          // for Spring Boot back-end
        //   if (route.data.roles && route.data.roles.indexOf(user.role) === -1) {
        //     // role not authorised so redirect to home page
        //     this.router.navigate(['/admin/home']);
        //     return false;
        //   }
          // authorised so return true
          return true;
          // for Node.js Express back-end
          // authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, token) });
        }
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
        // const user = this.authenticationService.userValue;
        // if (user) {
        //     // check if route is restricted by role
        //     if (route.data.roles && route.data.roles.indexOf(user.role) === -1) {
        //         // role not authorised so redirect to home page
        //         this.router.navigate(['/admin/home']);
        //         return false;
        //     }

        //     // authorised so return true
        //     return true;
        // }

        // // not logged in so redirect to login page with the return url
        // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        // return false;
    }
}