import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { baidang } from 'src/app/models/baidang';
import { chuyenmuc } from 'src/app/models/chuyenmuc';
import { TintucService } from 'src/app/services/tintuc.service';
declare var Quill: any;
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-chitietbd',
  templateUrl: './chitietbd.component.html',
  styleUrls: ['./chitietbd.component.css', './chitietbd.component.scss']

})
export class ChitietbdComponent implements OnInit {
  list_cm: chuyenmuc[];
  cao: string = '320px';
  rong: string = '600px';
  ketqua: string;
  displayDialog: boolean;
  fileToUpload: File = null;
  strHeader: string = 'Nội dung';
  srcImg: string = '';
  base64: any;
  bai_dang: baidang = {
    AnhDaiDien: '',
    ChuyenMuc_ID: 0,
    IDBaiDang: 0,
    IDTaiKhoan: '',
    NgayDang: '',
    NoiDung: '',
    TenBaiDang: '',
    Ten_chuyenmuc: '',
    TieuDe: '',
    TrangThaiBaiDang: '',
    TrichYeu: '',
    UrlRequire: ''
  };
  strIconMaxMin: string = '<i class="fas fa-window-maximize"></i>';
  constructor(private routes: ActivatedRoute, private tintucService: TintucService, private router: Router, private messageService: MessageService, private domSanitizer: DomSanitizer) {
    var fontSizeStyle = Quill.import('attributors/style/size');
    fontSizeStyle.whitelist = ['24px', '48px', '100px', '200px'];
    Quill.register(fontSizeStyle, true);
  }
  propagateChange = (_: any) => { };
  propagateTouched = () => { };
  registerOnChange(fn: (_: any) => void): void { this.propagateChange = fn; }
  registerOnTouched(fn: () => void): void { this.propagateTouched = fn; }
  public value: string;
  ngOnInit(): void {
    this.getListBaidang();
    this.getListCM();
    this.srcImg = `data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Cpath%20fill%3D%22%23666%22%20d%3D%22M0%2C0v2v4h2V2h4V0H2H0z%20M16%2C0h-4v2h4v4h2V2V0H16z%20M16%2C16h-4v2h4h2v-2v-4h-2V16z%20M2%2C12H0v4v2h2h4v-2H2V12z%22%2F%3E%0A%3C%2Fsvg%3E%0A`;
    this.base64 = this.domSanitizer.bypassSecurityTrustUrl(this.srcImg);
  }
  writeValue(value: any) {
    this.value = value;
  }

  onTextChanged(event) {
    this.propagateChange(event.htmlValue);
  }
  getListBaidang() {
    this.routes.params.subscribe(params => {

      let latestID = params['id'] == 'add' ? 0 : params['id'];
      this.tintucService.getBaiDangByID(latestID).subscribe(
        mv => {
          this.bai_dang = mv;
        }
      );
    });

  }
  getListCM() {
    this.tintucService.getListChuyenMuc().subscribe(
      mv => {
        this.list_cm = mv;
      }
    );
  }
  showDialogMaximized() {
    this.displayDialog = true;
  }
  onSubMit(bd: any) {
    debugger;
    if (this.fileToUpload != null) {
      this.tintucService.postFile(this.fileToUpload).subscribe(data => {
        if (data == true) {
          bd.AnhDaiDien = '/Images/' + this.fileToUpload.name;
          debugger;
          if (bd.IDBaiDang != 0) {
            this.tintucService.capnhatBD(bd).subscribe(
              kq => {

                this.setKq(kq);
                if (this.ketqua === 'OK') {
                  this.showMessage('success', 'Bài đăng đã cập nhật thành công!');
                }
                else {
                  this.showMessage('error', 'Có lỗi trong quá trình lưu dữ liệu');
                }
              }
            );
          } else {
            bd.IDCM = 0;
            this.tintucService.AddBD(bd).subscribe(
              kq => {
                this.setKq(kq);
                if (this.ketqua === 'OK') {
                  this.showMessage('success', 'Thêm mới bài đăng thành công!');
                }
                else {
                  this.showMessage('error', 'Có lỗi trong quá trình lưu dữ liệu');
                }
              }
            );
          }
        } else {
          this.showMessage('error', 'Có lỗi trong quá trình lưu dữ liệu');
        }
      }, error => {
        console.log(error);
      });
    }
    else {
      debugger;
      if (bd.IDBaiDang != 0) {
        this.tintucService.capnhatBD(bd).subscribe(
          kq => {
            this.setKq(kq);
            if (this.ketqua === 'OK') {
              this.showMessage('success', 'Bài đăng đã cập nhật thành công!');
            }
            else {
              this.showMessage('error', 'Có lỗi trong quá trình lưu dữ liệu');
            }
          }
        );
      } else {
        bd.IDCM = 0;
        this.tintucService.AddBD(bd).subscribe(
          kq => {
            this.setKq(kq);
            debugger;
            if (this.ketqua === 'OK') {
              this.showMessage('success', 'Thêm mới bài đăng thành công!');
            }
            else {
              this.showMessage('error', 'Có lỗi trong quá trình lưu dữ liệu');
            }
          }
        );
      }
    }

  }
  setKq(kq) {
    this.ketqua = kq;
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.bai_dang.AnhDaiDien = this.fileToUpload.name;
  }
  showMessage(ketqua: string, noidung: string) {
    if (ketqua == 'success')
      this.messageService.add({ severity: ketqua, summary: 'Thông báo', detail: noidung, life: 3000 });
    else
      this.messageService.add({ severity: ketqua, summary: 'Thông báo', detail: noidung });
  }
  goBack() {
    this.router.navigateByUrl(`admin/baidang`);
  }
  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
    if (this.status == true)
      this.srcImg = `data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Cpath%20fill%3D%22%23666%22%20d%3D%22M4%2C4H0v2h6V0H4V4z%20M14%2C4V0h-2v6h6V4H14z%20M12%2C18h2v-4h4v-2h-6V18z%20M0%2C14h4v4h2v-6H0V14z%22%2F%3E%0A%3C%2Fsvg%3E%0A`;
    else
      this.srcImg = `data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Cpath%20fill%3D%22%23666%22%20d%3D%22M0%2C0v2v4h2V2h4V0H2H0z%20M16%2C0h-4v2h4v4h2V2V0H16z%20M16%2C16h-4v2h4h2v-2v-4h-2V16z%20M2%2C12H0v4v2h2h4v-2H2V12z%22%2F%3E%0A%3C%2Fsvg%3E%0A`;
    this.base64 = this.domSanitizer.bypassSecurityTrustUrl(this.srcImg);
  }
}
