import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TintucService } from 'src/app/services/tintuc.service';
import { chuyenmuc } from 'src/app/models/chuyenmuc';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-chuyenmuc',
  templateUrl: './chuyenmuc.component.html',
  styleUrls: ['./chuyenmuc.component.css', './chuyenmuc.component.scss']
})
export class ChuyenmucComponent implements OnInit {
  list_cm: chuyenmuc[];
  chuyenmucDialog: boolean;
  chuyen_muc: chuyenmuc;
  submitted: boolean;
  ketqua: string;
  strHeader:string='';
  constructor(private routes: ActivatedRoute, private tintucService: TintucService, private messageService: MessageService, private primengConfig: PrimeNGConfig,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.getListCM();
  }
  getListCM() {
    this.tintucService.getListChuyenMuc().subscribe(
      mv => {
        this.list_cm = mv;
      }
    );
  }
  openNew() {
    this.chuyen_muc = {
      IDCM: 0,
      IDCMCha: 0,
      Link: '',
      TenCM: ''
    };
    this.strHeader='Thêm mới chuyên mục';
    this.submitted = false;
    this.chuyenmucDialog = true;
  }
  editchuyenmuc(item) {
    this.strHeader='Cập nhật chuyên mục';
    this.chuyen_muc = { ...item };
    this.chuyenmucDialog = true;
  }
  saveChuyenmuc() {
    this.submitted = true;
    if (this.chuyen_muc.TenCM.trim()) {
      if (this.chuyen_muc.IDCM) {
        this.tintucService.capnhatCM(this.chuyen_muc).subscribe(
          kq => {
            this.setKq(kq);
            if (this.ketqua === 'OK') {
              this.list_cm[this.findIndexById(this.chuyen_muc.IDCM)] = this.chuyen_muc;
              this.showMessage('success', 'Chuyên mục đã cập nhật thành công!');
              this.getListCM();
            }
            else {
              this.showMessage('error', 'Có lỗi trong quá trình lưu dữ liệu');
            }
          }
        );
      }
      else {
        this.chuyen_muc.IDCM = 0;
        this.tintucService.AddCM(this.chuyen_muc).subscribe(
          kq => {
            this.setKq(kq);
            if (this.ketqua === 'OK') {
              this.showMessage('success', 'Thêm mới chuyên mục thành công!');
              this.getListCM();
            }
            else {
              this.showMessage('error', 'Có lỗi trong quá trình lưu dữ liệu');
            }
          }
        );

      }

      this.list_cm = [...this.list_cm];
      this.chuyenmucDialog = false;
      this.chuyen_muc = {
        IDCM: 0,
        IDCMCha: 0,
        Link: '',
        TenCM: ''
      };
    }
  }
  deletechuyenmuc(product: chuyenmuc) {
    this.confirmationService.confirm({
      message: 'Bạn có muốn xoá chuyên mục: ' + product.TenCM + '?',
      header: 'Cảnh báo',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.tintucService.deleteCN(product.IDCM).subscribe(
          kq => {
            this.setKq(kq);
            if (this.ketqua === 'OK') {
              this.list_cm = this.list_cm.filter(val => val.IDCM !== product.IDCM);
              this.chuyen_muc = {
                IDCM: 0,
                IDCMCha: 0,
                Link: '',
                TenCM: ''
              };
              this.showMessage('success', 'Bạn đã thực hiện thành công');
            }
            else {
              this.showMessage('error', 'Có lỗi trong quá trình xoá dữ liệu');
            }
          }
        );
      }
    });
  }
  findIndexById(id: number): number {
    let index = -1;
    for (let i = 0; i < this.list_cm.length; i++) {
      if (this.list_cm[i].IDCM === id) {
        index = i;
        break;
      }
    }

    return index;
  }
  setKq(kq) {
    this.ketqua = kq;
  }
  hideDialog() {
    this.chuyenmucDialog = false;
    this.submitted = false;
  }
  showMessage(ketqua: string, noidung: string) {
    if (ketqua == 'success')
      this.messageService.add({ severity: ketqua, summary: 'Thông báo', detail: noidung, life: 3000 });
    else
      this.messageService.add({ severity: ketqua, summary: 'Thông báo', detail: noidung });
  }
}
