import { ElementRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'appuser-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(public elementRef: ElementRef) { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    var s9 = document.createElement("script");
    var s10 = document.createElement("script");
    s9.type = "text/javascript";
    s10.type = "text/javascript";
    s9.src = "../assets/HDSoft/js/chatbox.js";
    s10.src = "../assets/HDSoft/js/practice-animation.js";
    this.elementRef.nativeElement.appendChild(s9);
    // this.elementRef.nativeElement.appendChild(s10);
  }
}
