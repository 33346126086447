import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopNavbarComponent } from './users/top-navbar/top-navbar.component';
import { FooterComponent } from './users/footer/footer.component';
import { DstintucComponent } from './users/dstintuc/dstintuc.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MenubarModule } from 'primeng/menubar';
import {DataViewModule} from 'primeng/dataview';
import {ButtonModule} from 'primeng/button';
import {PanelModule} from 'primeng/panel';
import {DropdownModule} from 'primeng/dropdown';
import {DialogModule} from 'primeng/dialog';
import {InputTextModule} from 'primeng/inputtext';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { HomeComponent } from './users/home/home.component';
import { NotfoundpageComponent } from './users/notfoundpage/notfoundpage.component';
import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';
import { AdminLayoutComponent } from './_layout/admin-layout/admin-layout.component';
import { LoginComponent } from './admin/login/login.component';
import { fakeBackendProvider } from './_helpers/fake-backend';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { HomeadminComponent } from './admin/homeadmin/homeadmin.component';
import { ChuyenmucComponent } from './admin/chuyenmuc/chuyenmuc.component';
import { MessageService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {TableModule} from 'primeng/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { BaidangComponent } from './admin/baidang/baidang.component';
import { ChitietbdComponent } from './admin/chitietbd/chitietbd.component';
import { AppAsideModule, AppBreadcrumbModule, AppFooterModule, AppHeaderModule, AppSidebarModule } from '@coreui/angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import {EditorModule} from 'primeng/editor';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { TintucComponent } from './users/tintuc/tintuc.component';
import { CttintucComponent } from './users/cttintuc/cttintuc.component';
import {CarouselModule} from 'primeng/carousel';
import { LienheComponent } from './users/lienhe/lienhe.component';
@NgModule({
  declarations: [
    AppComponent,
    TopNavbarComponent,
    FooterComponent,
    DstintucComponent,
    HomeComponent,
    NotfoundpageComponent,
    SiteLayoutComponent,
    AdminLayoutComponent,
    LoginComponent,
    HomeadminComponent,
    ChuyenmucComponent,
    BaidangComponent,
    ChitietbdComponent,
    TintucComponent,
    CttintucComponent,
    LienheComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MenubarModule,
    DataViewModule,
    ButtonModule,
    PanelModule,
    DropdownModule,
    DialogModule,
    InputTextModule,
    RatingModule,
    RippleModule,
    BreadcrumbModule,
    ToastModule,
    TableModule,
    BrowserAnimationsModule,
    ConfirmDialogModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule, 
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    EditorModule,
    CarouselModule
  ],
  // providers: [],
//   providers: [
//     MessageService,
//     ConfirmationService,
//     { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
//     { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

//     // provider used to create fake backend
//     fakeBackendProvider
// ],
// providers: [authInterceptorProviders],
  providers: [
    MessageService,
    ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    authInterceptorProviders
],
  bootstrap: [AppComponent]
})
export class AppModule { }
