import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { baidang } from 'src/app/models/baidang';
import { chuyenmuc } from 'src/app/models/chuyenmuc';
import { MenuModel } from '../models/MenuModel';
import { error } from 'protractor';
import { environment } from 'src/environments/environment';
import { allbaidang } from '../models/allbaidang';

@Injectable({
  providedIn: 'root'
})
export class TintucService {
  // private tintucUrl = "http://192.168.10.35:8084/api";
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json', 
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Origin': '*'
    })
  };
  private tintucUrl = `${environment.apiUrl}`;
  getListChuyenMuc(): Observable<chuyenmuc[]> {
    const urlCM = `${this.tintucUrl}/chuyenmuc`;
    return this.http.get<chuyenmuc[]>(urlCM).pipe(
      tap(),
      catchError(error => of([]))
    );
  }
  getListMenu(): Observable<MenuModel[]> {
    const urlCM = `${this.tintucUrl}/menu`;
    return this.http.get<MenuModel[]>(urlCM).pipe(
      tap(),
      catchError(error => of([]))
    );
  }
  getListBaiDang_ad(): Observable<baidang[]> {
    const urlBD = `${this.tintucUrl}/baidang`;
    return this.http.get<baidang[]>(urlBD).pipe(
      tap(),
      catchError(error => of([]))
    );
  }
  getBaiDangByID(id: number): Observable<baidang> {
    const urlCT = `${this.tintucUrl}/baidang/${id}`;
    return this.http.get<baidang>(urlCT).pipe(
      tap(),
      catchError(error => of(new baidang()))
    );
  }
  getListBaiDang(Link: string): Observable<baidang[]> {
    let arrURL = Link.split('/');
    const urlBD = `${this.tintucUrl}/listbaidang/${arrURL[0]}`;
    return this.http.get<baidang[]>(urlBD).pipe(
      tap(),
      catchError(error => of(error))
    );
  }
  getListBaiDangreq(url_chon: string): Observable<baidang> {
    const urlBD = `${this.tintucUrl}/baidangurl/${url_chon}`;
    return this.http.get<baidang>(urlBD).pipe(
      tap(),
      catchError(error => of(new baidang()))
    );
  }
  getAllListBaiDang(): Observable<baidang[]> {
    const urlallBD = `${this.tintucUrl}/allbaidang`;
    return this.http.get<allbaidang[]>(urlallBD).pipe(
      tap(),
      catchError(error => of(error))
    );
  }
  capnhatCM(cm: chuyenmuc): Observable<chuyenmuc> {

    return this.http.put<chuyenmuc>(`${this.tintucUrl}/UpdateChuyenMuc/`, cm, this.httpOptions).pipe();
  }
  AddCM(cm: chuyenmuc): Observable<chuyenmuc> {
    // const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<chuyenmuc>(`${this.tintucUrl}/CreateChuyenMuc/`, cm, this.httpOptions).pipe();
  }
  deleteCN(id: number) {
    return this.http.delete(`${this.tintucUrl}/DeleteChuyenMuc/${id}`,).pipe();
  }
  deleteBaiDang(id: number) {
    return this.http.delete(`${this.tintucUrl}/DeleteBaiDang/${id}`,).pipe();
  }
  capnhatBD(bd: baidang): Observable<baidang> {
    // const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put<baidang>(`${this.tintucUrl}/UpdateBaiDang/`, bd, this.httpOptions).pipe();
  }
  AddBD(bd: baidang): Observable<baidang> {
    // const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<baidang>(`${this.tintucUrl}/CreateBaiDang/`, bd, this.httpOptions).pipe();
  }
  postFile(fileToUpload: File): Observable<boolean> {
    let formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    console.log(formData['fileKey']);
    return this.http.post<boolean>(`${this.tintucUrl}/UploadFile/`, formData).pipe();
  }
  sendMail(ttmail: any): Observable<any> {    
    return this.http.post<boolean>(`${this.tintucUrl}/SendMail/`, ttmail, this.httpOptions).pipe();
  }
  constructor(private http: HttpClient) { }
}
