import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css', './admin/main.css', './admin/util.css', './admin/hamburgers.min.css', './admin/icon-font.min.css', './admin/animate.css', './admin/select2.min.css']

})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    error = '';
    form: any = {};
    isLoggedIn = false;
    isLoginFailed = false;
    errorMessage = '';
    roles: string[] = [];
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private tokenStorage: TokenStorageService,
        private authenticationService: AuthenticationService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.userValue) {
            this.router.navigate(['admin/home']);
        }
        //New
        // redirect to home if already logged in
        if (this.tokenStorage.getToken) {

            debugger;
            this.router.navigate(['admin/home']);
        }

    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    //   onSubmit() {
    //       this.submitted = true;

    //       // stop here if form is invalid
    //       if (this.loginForm.invalid) {
    //           return;
    //       }
    //       this.loading = true;
    //       debugger;
    //       this.authenticationService.login(this.f.username.value, this.f.password.value)
    //           .pipe(first())
    //           .subscribe({
    //               next: () => {
    //                   // get return url from query parameters or default to home page
    //                   const returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'admin/home';
    //                   console.log(returnUrl);
    //                   this.router.navigateByUrl(returnUrl);
    //               },
    //               error: error => {
    //                   this.error = error;
    //                   this.loading = false;
    //               }
    //           });
    //   }
    reloadPage(): void {
        window.location.reload();
    }
    onSubmit(): void {
        var dt = {
            username: this.f.username.value,
            password: this.f.password.value
        };
        this.authService.login(dt).subscribe(
            data => {
                debugger;
                if (data.messagerespone == "OK") {
                    this.tokenStorage.saveToken(data.messagerespone);
                    this.tokenStorage.saveUser(data);
                    this.isLoginFailed = false;
                    this.isLoggedIn = true;
                    this.roles = this.tokenStorage.getUser().roles;
                    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'admin/home';
                    console.log(returnUrl);
                    this.router.navigateByUrl(returnUrl);
                }
                else {
                    this.error = data.message;
                    this.isLoginFailed = true;
                }
            },
            err => {
                this.error = "Có lỗi hệ thống, vui lòng liên hệ quản trị";
                this.isLoginFailed = true;
            }
        );
    }
}
