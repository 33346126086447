import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { baidang } from 'src/app/models/baidang';
import { TintucService } from 'src/app/services/tintuc.service';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-baidang',
  templateUrl: './baidang.component.html',
  styleUrls: ['./baidang.component.css', './baidang.component.scss']
})
export class BaidangComponent implements OnInit {
  list_baidang: baidang[];
  ketqua: string;
  sobaiDang: number;
  constructor(private tintucService: TintucService, private router: Router, private messageService: MessageService, private confirmationService: ConfirmationService) { }
  ngOnInit(): void {
    this.getListBaiDang();    
  }
  getListBaiDang(): void {
    this.tintucService.getListBaiDang_ad().subscribe(
      (bd) => {
        this.list_baidang = bd;
        this.demDong();
      }
    );
  }
  openNew() {
    this.router.navigateByUrl(`admin/baidang/add`);
  }
  editbaidang(bai_dang: baidang) {
    this.router.navigateByUrl(`admin/baidang/${bai_dang.IDBaiDang}`);
  }
  deletebaidang(bai_dang: baidang) {
    this.confirmationService.confirm({
      message: 'Bạn có muốn xoá bài đăng này?',
      header: 'Cảnh báo',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.tintucService.deleteBaiDang(bai_dang.IDBaiDang).subscribe(
          kq => {
            this.setKq(kq);
            if (this.ketqua === 'OK') {
              this.list_baidang = this.list_baidang.filter(val => val.IDBaiDang !== bai_dang.IDBaiDang);
              this.showMessage('success', 'Bạn đã thực hiện thành công');
            }
            else {
              this.showMessage('error', 'Có lỗi trong quá trình xoá dữ liệu');
            }
          }
        );
      }
    });
  }
  setKq(kq) {
    this.ketqua = kq;
  }
  showMessage(ketqua: string, noidung: string) {
    if (ketqua == 'success')
      this.messageService.add({ severity: ketqua, summary: 'Thông báo', detail: noidung, life: 3000 });
    else
      this.messageService.add({ severity: ketqua, summary: 'Thông báo', detail: noidung });
  }
  demDong() {
    let total = 0;
    for (let sale of this.list_baidang) {
      total += sale.IDBaiDang;
    }
    debugger;
    this.sobaiDang = total;
  }
}
