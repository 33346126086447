import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { baidang } from 'src/app/models/baidang';
import { TintucService } from 'src/app/services/tintuc.service';

@Component({
  selector: 'app-tintuc',
  templateUrl: './tintuc.component.html',
  styleUrls: ['./tintuc.component.css','./tintuc.component.scss']
})
export class TintucComponent implements OnInit {
  lisbd: baidang[];
  constructor(private routes: ActivatedRoute, private tintucService: TintucService, private router: Router) { }

  ngOnInit(): void {
    this.getListBaidang();
  }
  getListBaidang() {
    this.routes.params.subscribe(params => {
      this.tintucService.getListBaiDang('tin-tuc').subscribe(
        mv => {
          this.lisbd = mv;
        }
      );
    });

  }
}
