import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { baidang } from 'src/app/models/baidang';
import { TintucService } from 'src/app/services/tintuc.service';

@Component({
  selector: 'app-cttintuc',
  templateUrl: './cttintuc.component.html',
  styleUrls: ['./cttintuc.component.css','./cttintuc.component.scss']
})
export class CttintucComponent implements OnInit {
  bdChon:baidang={
    AnhDaiDien:'',
    ChuyenMuc_ID:0,
    IDBaiDang:0,
    IDTaiKhoan:'',
    NgayDang:'',
    NoiDung:'',
    TenBaiDang:'',
    Ten_chuyenmuc:'',
    TieuDe:'',
    TrangThaiBaiDang:'',
    TrichYeu:'',
    UrlRequire:''
  };
  lisbd: baidang[];
  responsiveOptions;
  constructor(public elementRef: ElementRef,private routes: ActivatedRoute, private tintucService: TintucService) { 
    this.responsiveOptions = [
      {
          breakpoint: '1024px',
          numVisible: 3,
          numScroll: 3
      },
      {
          breakpoint: '768px',
          numVisible: 2,
          numScroll: 2
      },
      {
          breakpoint: '560px',
          numVisible: 1,
          numScroll: 1
      }
  ];
  }

  ngOnInit(): void {
    this.getBaidang();
  }
  ngAfterView() {
    // this.getAllListBaidang()
    debugger;
    var s9 = document.createElement("script");
    var s10 = document.createElement("script");
    s9.type = "text/javascript";
    s10.type = "text/javascript";
    s9.src = "../assets/HDSoft/js/main.js";
    s10.src = "../assets/HDSoft/js/practice-animation.js";
    this.elementRef.nativeElement.appendChild(s9);
    this.elementRef.nativeElement.appendChild(s10);
  }
  getBaidang() {
    this.routes.params.subscribe(params => {
      let url = params['UrlRequire'];
      this.tintucService.getListBaiDangreq(url).subscribe(
        mv => {          
          this.bdChon = mv;
        }
      );
      this.tintucService.getListBaiDang('tin-tuc').subscribe(
        mv => {
          this.lisbd = mv.filter(val => val.UrlRequire !== url);
          this.ngAfterView();
        }
      );
      
    });
  }  
}
