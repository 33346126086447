import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { baidang } from 'src/app/models/baidang';
import { TintucService } from 'src/app/services/tintuc.service';
import { MenuItem } from 'primeng/api';
declare var Quill: any;

@Component({
  selector: 'app-dstintuc',
  templateUrl: './dstintuc.component.html',
  styleUrls: ['./dstintuc.component.css', './dstintuc.component.scss']
})
export class DstintucComponent implements OnInit {
  lisbd: baidang[];
  ten_cm: string = '';
  selectBaiDang: baidang = {
    AnhDaiDien: '',
    ChuyenMuc_ID: 0,
    IDBaiDang: 0,
    IDTaiKhoan: '',
    NgayDang: '',
    NoiDung: '',
    TenBaiDang: '',
    TieuDe: '',
    TrangThaiBaiDang: '',
    TrichYeu: '',
    UrlRequire: '',
    Ten_chuyenmuc: ''
  };
  constructor(private routes: ActivatedRoute, private tintucService: TintucService, private router: Router) { 
    var fontSizeStyle = Quill.import('attributors/style/size');
    fontSizeStyle.whitelist = ['24px', '48px', '100px', '200px'];
    Quill.register(fontSizeStyle, true);
  }

  ngOnInit(): void {
    this.getListBaidang();
  }

  getListBaidang() {
    this.routes.params.subscribe(params => {
      debugger;
      let latestID = params['Link'];
      let url = params['UrlRequire'];
      this.tintucService.getListBaiDang(latestID).subscribe(
        mv => {
          debugger;
          this.lisbd = mv;
          if (this.lisbd.length == undefined) {
            this.goToItems();
            return;
          }
          let a = this.lisbd[0];
          if (a.TieuDe == 'NOT_FOUND') {
            this.goToItems();
            return;
          }
          this.ten_cm = a.Ten_chuyenmuc;
          if (url != undefined) {
            var foundIndex = this.lisbd.findIndex(x => x.UrlRequire == url);
            this.onSelect(this.lisbd[foundIndex]);
          } else
            this.onSelect(a);
        }
      );
    });

  }
  onSelect(item: baidang): void {
    this.selectBaiDang = item;
  }
  goToItems() {
    this.router.navigateByUrl(`notfound`);
  }
}
