import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { navItems } from '../../_nav';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css']
})
export class AdminLayoutComponent implements OnInit {

  user: User;
  public sidebarMinimized = false;
  public navItems = navItems;
  constructor(private authenticationService: AuthenticationService, private tokenStorageService: TokenStorageService, private router: Router,) {
    this.authenticationService.user.subscribe(x => this.user = x);
  }

  ngOnInit(): void {
  }
  logout() {
    // this.authenticationService.logout();
    this.tokenStorageService.signOut();
    // window.location.reload();
    this.router.navigate(['/login']);
  }
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}
